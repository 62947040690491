import React from "react"
import firebase from "firebase/app"

export const Logout = () => {
  return (
    <div
      className="logout"
      onClick={() => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            window.location.pathname = "/login"
          })
      }}
    >
      <i className="fas fa-sign-out-alt"></i>
    </div>
  )
}
