import { Font, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import fontBold from "../certificates/RobotoSlab-Bold.ttf"
import fontLight from "../certificates/RobotoSlab-Light.ttf"

Font.register({
  family: "RobotoSlab-Bold",
  src: fontBold,
  fontStyle: "normal",
  fontWeight: 400,
})

Font.register({
  family: "RobotoSlab-Light",
  src: fontLight,
  fontStyle: "normal",
  fontWeight: 400,
})

interface Props {
  text: string
  fontSize: number
}

export default function SplitWords(props: Props) {
  const { text, fontSize } = props

  const words = text.split(" ")

  return words
    .filter((x) => x.trim() !== "")
    .map((wo, index) => {
      return (
        <View style={styles.container} key={index}>
          <Text style={[styles.word, { fontSize }]}>{wo}</Text>
        </View>
      )
    })
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  word: {
    textAlign: "center",
    fontFamily: "RobotoSlab-Bold",
    fontWeight: 400,
    paddingHorizontal: 4,
    display: "flex",
  },
})
