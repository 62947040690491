import firebase from "firebase/app"
import { useFormik } from "formik"
import { sortBy } from "lodash"
import moment from "moment"
import React, { useState } from "react"
import { Button, Col, Form, Spinner } from "react-bootstrap"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { Logout } from "../../components/logout"
import { coursesRef, settingsCollectionRef } from "../../config/collectionsRef"
import "../../config/firebase"
import { generatePdfAndUpload } from "../certificates/generatePdfAndUpload"

const CertificatesCreate = () => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())

  const id = user?.uid ?? "NotLoad"

  const [currentUser] = useDocument(
    firebase.firestore().collection("portalUsers").doc(id)
  )

  const currentUserData = currentUser?.data()
  const isFullAdmin = currentUserData?.permission == "fullAdmin"

  const query =
    currentUserData?.permission == "fullAdmin"
      ? coursesRef.where("status", "==", "approved")
      : coursesRef
          .where("agent", "==", user?.uid ?? "NotLoad")
          .where("status", "==", "approved")

  const [courseSnapshot, loading] = useCollection(query)

  const [generateLoading, setGenerateLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      fullNames: "",
      courseId: "",
      certificateType: "attendance",
      givenDate: undefined,
      courseHours: undefined,
      towSignature: false,
      directorName: "",
      PartnerOf: "",
      JobTitle: "",
    },

    onSubmit: async (values) => {
      try {
        setGenerateLoading(true)

        const usersValues = values.fullNames
          .split(/\,|\n/)
          .filter((x) => x.trim() !== "")
          .map((x) => x.trim())

        for (const userFullName of usersValues) {
          const settingsRef = await settingsCollectionRef
            .doc("cfhIQB5wn2ZjAoqSMOoD")
            .get()

          const settingsData = settingsRef?.data()

          const course = courseSnapshot?.docs
            .find((e) => e.id == values.courseId)
            ?.data()

          const courseName = await course?.name

          const templateData = {
            fullName: userFullName.trim(),
            certificateType: values.certificateType,
            givenDate: moment(values.givenDate).format("LL"),
            courseHours: values.courseHours,
            courseName: courseName,
            certNumber: settingsData?.lastCertificateNumber,

            towSignature: course?.towSignature,
            signatureImg: course?.signatureImg,
            directorName: course?.directorName,
            PartnerOf: course?.PartnerOf,
            JobTitle: course?.JobTitle,
          }

          await generatePdfAndUpload(
            templateData as any,
            values.courseId,
            course?.agent
          )
        }

        formik.resetForm()

        setGenerateLoading(false)

    

        await fetch(
          "https://us-central1-mastery-academy-f8223.cloudfunctions.net/sendToCertificatesRequestToAdmins",
          {
            method: "POST",
            body: JSON.stringify({
              reqData: {
                agentId: id,
                studentsList: usersValues,
              },
            }),
          }
        )
      } catch (e) {
        alert(e)
      }
    },
  })

  const generateIsDisabled =
    formik.values.courseId.trim() === "" ||
    formik.values.givenDate === undefined ||
    formik.values.courseHours === undefined

  const coursesSorted = !courseSnapshot
    ? []
    : sortBy(courseSnapshot?.docs, (x) => x.data().createdAt).reverse()

  return (
    <>
      <section className="formSection" style={{ marginTop: 200 }}>
        <div className="container">
          <div className="row">
            <Form className="col-12" id="create-box">
              <Form.Group>
                <Form.Row className="mt-25">
                  <Form.Label column lg={12}>
                    Graduates Full Names *
                  </Form.Label>
                  <Col lg={12} className="mt-10">
                    <Form.Control
                      as="textarea"
                      onChange={(e: any) =>
                        formik.setFieldValue("fullNames", e.target.value)
                      }
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-25">
                  <Form.Label column lg={12}>
                    Course *
                  </Form.Label>
                  <Col lg={12} className="mt-10">
                    <Form.Control
                      as="select"
                      onChange={(e: any) => {
                        formik.setFieldValue("courseId", e.target.value)
                      }}
                    >
                      <option value="Not Selected"></option>

                      {coursesSorted.map((course) => {
                        const date = moment(
                          course.data()?.endDate.toDate()
                        ).format("L")

                        return (
                          <option key={course.id} value={course.id}>
                            {course.data()?.name + "   (" + date + ")   "}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-25">
                  <Form.Label column lg={12}>
                    Certificate type *
                  </Form.Label>
                  <Col lg={12} className="mt-10">
                    <Form.Control
                      as="select"
                      onChange={(e: any) =>
                        formik.setFieldValue("certificateType", e.target.value)
                      }
                    >
                      {(isFullAdmin
                        ? ["attendance", "achievement"]
                        : currentUserData?.certificateTypes
                      )?.map((x) => {
                        return (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Col>
                </Form.Row>

                <Form.Row className="mt-25">
                  <Form.Label column lg={12}>
                    Given Date *
                  </Form.Label>
                  <Col lg={12} className="mt-10">
                    <Form.Control
                      type="date"
                      onChange={(e: any) =>
                        formik.setFieldValue("givenDate", e.target.value)
                      }
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mt-25">
                  <Form.Label column lg={12}>
                    Course Hours *
                  </Form.Label>
                  <Col lg={12} className="mt-10">
                    <Form.Control
                      type="number"
                      onChange={(e: any) =>
                        formik.setFieldValue("courseHours", e.target.value)
                      }
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
              <Button
                className="col-12 "
                variant="primary"
                style={{ marginTop: 75 }}
                onClick={() => {
                  formik.submitForm()
                }}
                disabled={generateIsDisabled}
              >
                {generateLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span> Generating... </span>
                  </>
                ) : (
                  <span> Export a Certificate</span>
                )}
              </Button>
            </Form>
          </div>
        </div>

        <Logout />
      </section>
    </>
  )
}

export default CertificatesCreate
