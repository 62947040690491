import firebase from "firebase/app"
import uuidv4 from "uuid/v4"

export async function imageUploadAndGetUrl(
  value: File | Blob,
  src: "images" | "signatures"
): Promise<string> {
  let url = ""
  try {
    const storage = firebase.storage()

    const filename = `${uuidv4()}`

    await storage.ref(`${src}/${filename}`).put(value)

    url = `${src}/${filename}`
  } catch (e) {
    console.log({ e })
  }
  return url
}
