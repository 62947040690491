import { useDocument } from "react-firebase-hooks/firestore"
import { coursesRef } from "../../config/collectionsRef"

interface Props {
  id: string
}
export default function CourseNameById(props: Props) {
  const { id } = props

  const [snapshot] = useDocument(coursesRef.doc(id))
  const data = snapshot?.data()

  return data?.name ?? ""
}
