import {
  Checkbox,
  DatePicker,
  Input,
  message,
  Modal,
  Select,
  Upload,
} from "antd"
import "antd/dist/antd.css"
import firebase from "firebase/app"
import { useFormik } from "formik"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore"
import { useCollection } from "react-firebase-hooks/firestore/"
import { coursesRef, usersCollectionRef } from "../../config/collectionsRef"
const { Option } = Select
const { Dragger } = Upload

interface Props {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  courseId: string
}

interface Course {
  name: string
  towSignature: boolean
  agent: string
  status: "approved" | "pendingApproval"
  endDate: Date
  createdAt: Date
  signatureImg?: string
  directorName?: string
  PartnerOf?: string
  JobTitle?: string
}

const EditCourseDialog = (props: Props) => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())
  const { setVisible, visible, courseId } = props
  const [firstLoad, setFirstLoad] = useState(true)

  const userId = user?.uid ?? "NotLoad"

  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(userId)
  )

  const [courseSnapshpt, loadingCourse] = useDocumentData<Course>(
    coursesRef.doc(courseId)
  )

  const permission = userSnapshot?.data()?.permission

  const isFullAdmin = permission === "fullAdmin"

  const [usersSnapshot, loading] = useCollection(usersCollectionRef)

  const formik = useFormik({
    initialValues: {} as any,

    onSubmit: async (values: Course) => {
      const selectedAgent = usersSnapshot?.docs
        .find((x) => x.id === formik.values.agent)
        ?.data()


      if (!selectedAgent) {
        return
      }


      await coursesRef.doc(courseId).set(
        {
          name: values.name,
          towSignature: values.towSignature,
          agent: values.agent,
          status: isFullAdmin ? "approved" : "pendingApproval",
          endDate: values.endDate as any,
          createdAt: new Date(),
          ...(values.towSignature
            ? {
                signatureImg: selectedAgent.signatureImg,
                directorName: selectedAgent.directorName,
                PartnerOf: selectedAgent.PartnerOf,
                JobTitle: selectedAgent.JobTitle,
              }
            : {}),
        },
        { merge: true }
      )

      formik.resetForm()
      setVisible(false)
      await message.success(`Updated Course  ${formik.values.name}`)
    },
  })

  useEffect(() => {
    if (!courseSnapshpt || !firstLoad) {
      return
    }
    setFirstLoad(false)
    formik.setValues(courseSnapshpt as any)
  }, [courseSnapshpt, firstLoad, setFirstLoad])

  if (loading || loadingCourse) {
    return null
  }

  const isDisabled = formik.values.name?.trim() === ""

  return (
    <Modal
      title="Edit Course"
      visible={visible}
      onOk={() => formik.submitForm()}
      onCancel={() => setVisible(false)}
      okButtonProps={{ disabled: isDisabled }}
      style={{ marginTop: 100 }}
    >
      <label style={{ marginTop: 25 }}> Course Name * </label>
      <Input
        placeholder="Enter Course Name"
        onChange={(e) => formik.setFieldValue("name", e.target.value)}
        value={formik.values.name}
      />

      <label style={{ marginTop: 25 }}>
        End Date <b style={{ color: "red" }}>*</b>{" "}
      </label>

      <DatePicker
        value={
          formik.values.endDate instanceof firebase.firestore.Timestamp
            ? moment(
                (formik.values.endDate as firebase.firestore.Timestamp).toDate()
              )
            : moment(formik.values.endDate)
        }
        style={{ width: "100%" }}
        placeholder="Select End Date"
        onChange={(x) => {
          if (!x) {
            return
          }
          formik.setFieldValue("endDate", x.toDate())
        }}
      />

      {isFullAdmin && (
        <Checkbox
          style={{ marginTop: 25 }}
          checked={formik.values.towSignature}
          onChange={(e) => {
            formik.setFieldValue("towSignature", !formik.values.towSignature)
          }}
        >
          Do You Wont This Certificate With two Signature
        </Checkbox>
      )}
    </Modal>
  )
}

export default EditCourseDialog
