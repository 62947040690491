import React, { useState } from "react"
import { Spinner } from "react-bootstrap"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { certificatesRef } from "../../config/collectionsRef"
import { Certificate } from "../../database/interfaces"
import CourseNameById from "./CouresNameById"
import StorageLink from "./storgeLink"

interface Props {
  courseId: string
  search?: string
  isFullAdmin?: boolean
}

interface Record extends Certificate {
  id: string
}

function CourseCertificatesList(props: Props) {
  const { courseId, search, isFullAdmin } = props

  const [loadingCertificates, setLoadingCertificates] = useState(false)
  const [certificates, Loading] = useCollectionData<Record>(
    certificatesRef
      .where("courseId", "==", courseId)
      .where("status", "==", "approved"),
    { idField: "id" }
  )

  if (!certificates || Loading) {
    return null
  }

  const deleteCertificate = (certificateId: string) => {
    const r = window.confirm(
      "Are you sure you wont to Delete This Certificate?"
    )
    if (r == true) {
      certificatesRef.doc(certificateId).delete()
    }
  }

  const downloadMargePdfFile = async (certificates: any[]) => {
    try {
      setLoadingCertificates(true)
      const response = await fetch(
        "https://us-central1-mastery-academy-f8223.cloudfunctions.net/createCourseCertificatesPdf",
        {
          method: "POST",
          body: JSON.stringify({
            certificatesIds: certificates.map((cer) => !!cer && cer?.id),
          }),
        }
      )

      const resJson = await response.json()

      window.open(resJson.url, "_blank")
    } catch (e) {
      setLoadingCertificates(false)
    } finally {
      setLoadingCertificates(false)
    }
  }

  const listData = search
    ? certificates.filter(
        (x) =>
          x.studentName.trim().toLocaleLowerCase().includes(search) ||
          x.number.trim().toLocaleLowerCase().includes(search)
      )
    : certificates

  if (!listData.length) {
    return null
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "26px 0px",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>
          <CourseNameById id={courseId} />
        </h2>
        <button
          className="btn btn-primary"
          style={{ margin: "0px 16px" }}
          onClick={() => {
            downloadMargePdfFile(certificates)
          }}
        >
          Download All Certificates
          {loadingCertificates ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ margin: 4 }}
            />
          ) : null}
        </button>
      </div>

      <div className="container">
        <div className="row">
          {listData.map((certificate) => {
            return (
              <div className="col-4">
                {isFullAdmin ? (
                  <div
                    id="deleteItem"
                    onClick={() => {
                      deleteCertificate(certificate.id)
                    }}
                  >
                    <i className="fas fa-trash" style={{ color: "red" }}></i>
                  </div>
                ) : null}

                <StorageLink storagePath={certificate.certificateUrl}>
                  <div id="resultBox">
                    <i
                      style={{ fontSize: 26 }}
                      className="fas fa-graduation-cap"
                    ></i>
                    <span id="studentName">{certificate.studentName}</span>
                    <span id="studentName"> {certificate.number} </span>
                  </div>
                </StorageLink>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default React.memo(CourseCertificatesList)
