import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import "../../css/style.css"
import logo from "/images/logo-wide-04-02.png"
interface Props {
  permission: "fullAdmin" | "agent"
  locale: "ar" | "en"
}

export const AdminHeader = (props: Props) => {
  const { permission, locale } = props
  return (
    <header className="header-area">
      <Helmet bodyAttributes={{ class: `locale-${locale}` }} />
      <div className="header-menu">
        <div className="navigation">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <div className="mr-auto ml-auto">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarFive"
                      aria-controls="navbarFive"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse sub-menu-bar"
                      id="navbarFive"
                    >
                      <ul className="navbar-nav ">
                        {permission == "fullAdmin" ? (
                          <>
                            <li className={`nav-item`}>
                              <Link
                                className="page-scroll"
                                to="/agents-Table"
                              >
                                Agents
                              </Link>
                            </li>


                            <li className={`nav-item`}>
                              <Link
                                className="page-scroll"
                                to="/certificates-approve"
                              >
                                Certificates Approve
                              </Link>
                            </li>
                          </>
                        ) : null}


                        <li className={`nav-item`}>
                          <Link
                            className="page-scroll"
                            to="/courses-Table"
                          >
                            Courses
                              </Link>
                        </li>

                        <li className={`nav-item`}>
                          <Link
                            className="page-scroll"
                            to="/certificates-search"
                          >
                            Certificates
                          </Link>
                        </li>

                        <li className={`nav-item`}>
                          <Link
                            className="page-scroll"
                            to="/certificates-create"
                          >
                            Export Certificates
                          </Link>
                        </li>


                      </ul>

                      <div className="navbar-nav ">
                        <img className="pageLogo" src={logo} height="80" />
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
