import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer"
import { message } from "antd"
import React from "react"
import { certificatesRef } from "../../config/collectionsRef"
import { Certificate } from "../../database/interfaces"
import SplitWords from "../components/SplitWords"
import { firebaseUploadAndGetUrl } from "../utils/firebaseUploadAndGetUrl"
import fontBold from "./RobotoSlab-Bold.ttf"
import fontLight from "./RobotoSlab-Light.ttf"

Font.register({
  family: "RobotoSlab-Bold",
  src: fontBold,
  fontStyle: "normal",
  fontWeight: 400,
})

Font.register({
  family: "RobotoSlab-Light",
  src: fontLight,
  fontStyle: "normal",
  fontWeight: 400,
})

interface TemplateDate {
  fullName: string
  certificateType: "Attendance" | "Achievement"
  givenDate: Date
  courseHours: number
  courseName: string
  certNumber: number

  towSignature?: boolean
  signatureImg?: string
  directorName?: string
  PartnerOf?: string
  JobTitle?: string
}

export const generatePdfAndUpload = async (
  templateData: TemplateDate,
  courseId: string,
  agentId: string
) => {
  const year = new Date().getFullYear().toString().slice(-2)
  const month = new Date().getMonth().toString()
  const day = new Date().getDate().toString()
  const randomNumber = Math.floor(1000 + Math.random() * 9000)

  const certificateNumber = year + month + day + randomNumber

  const MyDoc = (
    <Document>
      <Page size="A4" style={styles.pageBody}>
        <View>
          <Text style={styles.headerText}>
            Mastery Academy for Training & Development Hereby certifies that
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <SplitWords
              fontSize={24}
              text={templateData.fullName.trim()}
              key="WordSplit"
            />
          </View>
        </View>
        <View>
          <Text style={styles.descriptionText}>
            demonstrated the skills and competence
          </Text>

          <Text style={styles.secondDescriptionText}>required to achieve</Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <SplitWords
              fontSize={24}
              text={templateData.courseName.trim()}
              key="WordSplit"
            />
          </View>

          <Text style={styles.courseNameDescription}>
            And, in recognition thereof, is hereby awarded this
          </Text>

          <Text style={styles.certificateType}>
            Certificate of  
            {' ' + templateData.certificateType.charAt(0).toUpperCase() +
              templateData.certificateType.slice(1)}
          </Text>

          <Text style={styles.certificateTypeDescription}>
            In witness thereof I have hereto subscribed
          </Text>

          <Text style={styles.certificateTypeSecondDescription}>
            my name & affixed the seal of the
          </Text>

          <Text style={styles.masteryAcademyForTreading}>
            Mastery Academy for Training & Development
          </Text>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ display: "flex" }}>
              <Text style={styles.givenDate}>
                <Text> Given </Text>
                <Text style={{ paddingLeft: 30 }}>
                  {templateData.givenDate}
                </Text>
              </Text>
            </View>

            <View style={{ marginLeft: 5 }}>
              <Text style={styles.courseHours}>
                ( {templateData.courseHours} Training Hours )
              </Text>
            </View>
          </View>

          <View
            style={
              !templateData.towSignature
                ? styles.oneSignaturesContainer
                : styles.towSignaturesContainer
            }
          >
            {templateData.towSignature ? (
              <View style={styles.signatureItemContainer}>
                {templateData.signatureImg !== undefined ? (
                  <Image
                    style={styles.signatureItemImage}
                    src={templateData.signatureImg!}
                  />
                ) : null}

                <Text style={styles.directorName}>
                  {templateData.directorName ?? ""}
                </Text>

                <Text style={styles.PartnerOf}>
                  {templateData.PartnerOf ?? ""}
                </Text>

                <Text style={styles.jobTitle}>
                  {templateData.JobTitle ?? ""}
                </Text>
              </View>
            ) : null}

            <View style={styles.signatureItemContainer}>
              <Image
                style={styles.signatureItemImage}
                src="https://firebasestorage.googleapis.com/v0/b/mastery-academy-f8223.appspot.com/o/signatures%2FSign.jpg?alt=media&token=29afe96a-e326-4b9e-ab5c-383cdc28ff2b"
              />

              <Text style={styles.masteryDirector}>Thabit M. Hejazi</Text>
              <Text style={styles.masteryAcademy}>Mastery Academy</Text>
              <Text style={styles.masteryAcademyDirector}>Director</Text>
            </View>
          </View>

          <View>
            <Text style={styles.certificateNumber}>{certificateNumber}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )

  const blob = await pdf(MyDoc).toBlob()

  const url = await firebaseUploadAndGetUrl(blob, "certificate")

  await certificatesRef.add({
    courseId: courseId,
    generatedAt: new Date(),
    number: certificateNumber as any,
    studentName: templateData.fullName,
    certificateUrl: url,
    courseName: templateData.courseName,
    status: "pendingApproval",
    agentId,
  } as Certificate)

  message.success(`Created Course Certificate And Wating For Approve`)
}

const styles = StyleSheet.create({
  pageBody: {
    paddingTop: 150,
    paddingBottom: 65,
    paddingHorizontal: 45,
  },
  headerText: {
    fontSize: 21,
    textAlign: "center",
    marginTop: 20,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
    paddingHorizontal: 18,
  },
  fullName: {
    textAlign: "center",
    marginTop: 10,
    fontFamily: "RobotoSlab-Bold",
    fontStyle: "normal",
    fontWeight: 400,
  },
  courseName: {
    textAlign: "center",
    marginTop: 15,
    fontFamily: "RobotoSlab-Bold",
    fontWeight: 400,
    paddingHorizontal: 16,
    fontSize: 26,
  },
  courseNameDescription: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 2,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  descriptionText: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 5,
    fontFamily: "RobotoSlab-Light",
  },
  secondDescriptionText: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "RobotoSlab-Light",
  },
  certificateType: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 16,
    paddingHorizontal: 25,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  certificateTypeDescription: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 18,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  certificateTypeSecondDescription: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  masteryAcademyForTreading: {
    fontSize: 21,
    textAlign: "center",
    marginTop: 8,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  givenDate: {
    fontSize: 10,
    paddingLeft: 30,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  courseHours: {
    fontSize: 10,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  towSignaturesContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-around",
    paddingHorizontal: 50,
  },
  oneSignaturesContainer: {
    alignItems: "flex-end",
    marginTop: 30,
    display: "flex",
  },
  signatureItemContainer: {
    display: "flex",
    width: 150,
    backgroundColor: "#FFF",
  },
  signatureItemImage: {
    width: 120,
    height: 50,
    marginBottom: 10,
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    paddingBottom: 5,
    backgroundColor: "#FFF",
  },
  directorName: {
    fontSize: 12,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  PartnerOf: {
    fontSize: 8,

    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  jobTitle: {
    fontSize: 8,

    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
  },
  masteryDirector: {
    fontSize: 12,
    paddingTop: 1,
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
    textAlign: "left",
  },
  masteryAcademy: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
    paddingTop: 1,
  },
  masteryAcademyDirector: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
    paddingTop: 1,
  },
  certificateNumber: {
    fontSize: 11,
    textAlign: "center",
    fontFamily: "RobotoSlab-Light",
    fontStyle: "normal",
    position: "absolute",
    bottom: -124,
    alignSelf: "center",
  },
})
