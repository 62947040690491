import firebase from "firebase/app"
import React from "react"
import { useDownloadURL } from "react-firebase-hooks/storage"

interface Props {
  storagePath: string
  children: React.ReactNode
}

export default function StorageLink(props: Props) {
  const [downloadUrl] = useDownloadURL(
    firebase.storage().ref(props.storagePath || "no")
  )

  if (downloadUrl == undefined) {
    return <b></b>
  }

  return (
    <a href={`${downloadUrl}`} target="_blank">
      {props.children}
    </a>
  )
}
