import { Spin } from "antd"
import firebase from "firebase/app"
import React from "react"
import ReactDOM from "react-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument } from "react-firebase-hooks/firestore"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import { usersCollectionRef } from "../config/collectionsRef"
import "../config/firebase"
import { AdminHeader } from "./components/AdminsHeader"
import AgentsTable from "./pages/agents-table"
import CertificateApprovedTable from "./pages/certificates-approve"
import CertificatesCreate from "./pages/certificates-create"
import CertificatesSearch from "./pages/certificates-search"
import CoursesTable from "./pages/courses-table"
import Login from "./pages/login"

function App() {
  const [user, isLoadingUser] = useAuthState(firebase.auth())

  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(user?.uid || "noUser")
  )

  if (isLoadingUser || loadingUserData) {
    return <Spin />
  }

  const permission = userSnapshot?.data()?.permission

  const isFullAdmin = permission === "fullAdmin"
  const notLoggedIn = !userSnapshot?.exists

  return (
    <Router>
      <Switch>
        {notLoggedIn ? (
          <Route path="/login">
            <Login />
          </Route>
        ) : null}

        {notLoggedIn ? null : (
          <>
            <AdminHeader permission={permission} locale="en" />

            <Route path="/certificates-create">
              <CertificatesCreate />
            </Route>

            <Route path="/certificates-approve">
              <CertificateApprovedTable />
            </Route>

            <Route path="/certificates-search">
              <CertificatesSearch />
            </Route>

            <Route path="/courses-table">
              <CoursesTable />
            </Route>

            {isFullAdmin ? (
              <>
                <Route path="/agents-table">
                  <AgentsTable />
                </Route>
              </>
            ) : null}
          </>
        )}
        <Redirect to={notLoggedIn ? "/login" : "/certificates-search"} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById("app"))
