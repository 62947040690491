import firebase from "firebase/app"

export async function getFileDownloadUrl(path: string) {
  const downloadUrl = await firebase
    .storage()
    .ref(path)
    .getDownloadURL()
    .then((url) => {
      return url
    })

  return downloadUrl
}
