import {
  DataTypeProvider,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid"
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui"
import Paper from "@material-ui/core/Paper"
// import calendar style
// You can customize style by copying asset folder.
import "@y0c/react-datepicker/assets/styles/calendar.scss"
import { DatePicker, Spin } from "antd"
import { sortBy, sum, uniq } from "lodash"
import moment, { Moment } from "moment"
import React, { useState } from "react"
import { Logout } from "../../components/logout"
import {
  certificatesRef,
  coursesRef,
  usersCollectionRef,
} from "../../config/collectionsRef"
import { Certificate, Course, User } from "../../database/interfaces"
import { useAsyncLoad } from "../../hooks/useAsyncLoad"
import CreateAgentDialog from "../components/CreateAgenDialog"
import { DateRange } from "../components/DateRange"
import EditAgentDialog from "../components/EditAgentDialog"
import "./styles.css"
const { MonthPicker } = DatePicker

export interface UserWithId extends User {
  id: string
}

interface DataSource extends UserWithId {
  approvedCourses: number
  approvedCertificates: number
  numberOfStudents: number
}

type DateRangeStateType = [moment.Moment, moment.Moment] | null
type MonthDateStateType = Moment | null

const UsersTable = () => {
  const [monthDateFilter, setMonthDateFilter] = useState<MonthDateStateType>(
    null
  )
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeStateType>(
    null
  )

  const [editAgentDialog, setEditAgentDialog] = useState(false)
  const [editingId, setEditingId] = useState<string | undefined>()

  const [createAgentDialogVisible, setCreateAgentDialogVisible] = useState(
    false
  )

  const getDataSource = async () => {
    const agentsList = await usersCollectionRef
      .where("permission", "==", "agent")
      .get()

    let result: DataSource[] = []

    try {
      await Promise.all(
        agentsList.docs.map(async (agentData) => {
          const data = agentData.data() as UserWithId

          const agentApproverCoursesQuery = await coursesRef
            .where("agent", "==", agentData.id)
            .where("status", "==", "approved")
            .get()

          const agentApprovedCoursesData = agentApproverCoursesQuery.docs
            .map((ap) => {
              const data = ap.data() as Course
              const isSameMonth =
                monthDateFilter &&
                moment((data.createdAt as any).toDate()).isSame(
                  monthDateFilter,
                  "month"
                )

              const isSameRange =
                dateRangeFilter &&
                dateRangeFilter.length &&
                moment((data.createdAt as any).toDate()).isBetween(
                  dateRangeFilter[0],
                  dateRangeFilter[1]
                )

              if (monthDateFilter && !isSameMonth) {
                return null
              }

              if (dateRangeFilter && !isSameRange) {
                return null
              }

              return {
                ...data,
                id: ap.id,
              }
            })
            .filter((x) => !!x)

          const ApprovedCertificatesQuery = await certificatesRef
            .where("agentId", "==", agentData.id)
            .get()

          const ApprovedCertificatesData = ApprovedCertificatesQuery.docs
            .map((ap) => {
              const data = ap.data() as Certificate
              const isSameMonth =
                monthDateFilter &&
                moment((data.generatedAt as any).toDate()).isSame(
                  monthDateFilter,
                  "month"
                )

              const isSameRange =
                dateRangeFilter &&
                moment((data.generatedAt as any).toDate()).isBetween(
                  dateRangeFilter[0],
                  dateRangeFilter[1]
                )

              if (monthDateFilter && !isSameMonth) {
                return null
              }

              if (dateRangeFilter && !isSameRange) {
                return null
              }

              return {
                ...data,
                id: ap.id,
              }
            })
            .filter((x) => !!x)

          const studentsList = ApprovedCertificatesData.map((x) => {
            return {
              studentName: x.studentName,
              createdAt: x.generatedAt,
            }
          })

          const uniqStudents = uniq(
            studentsList.map((x) => x.studentName.toLocaleLowerCase().trim())
          )

          const value = {
            ...data,
            id: agentData.id,
            approvedCourses: agentApprovedCoursesData.length,
            approvedCertificates: ApprovedCertificatesData.length,
            numberOfStudents: uniqStudents.length,
          } as DataSource
          result = [...result, value]
        })
      )
    } catch (e) {
      console.log(e)
    }

    return result
  }

  const deleteAgent = (agentId: string) => {
    const r = window.confirm("Are you sure you wont to Delete This Agent?")
    if (r == true) {
      usersCollectionRef.doc(agentId).delete()
    }
  }

  const columns = [
    {
      name: "name",
      title: "Name",
    },
    {
      name: "email",
      title: "Email",
    },
    { name: "approvedCourses", title: "Approved Courses" },
    { name: "approvedCertificates", title: "Approved Certificates" },
    { name: "numberOfStudents", title: "Number Of Student" },

    { name: "directorName", title: "Director Name" },
    { name: "note", title: "Note" },
    {
      title: "Actions",
      key: "Actions",
      getCellValue: (row: any) => {
        return (
          <div style={{ display: "flex" }}>
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                setEditAgentDialog(true)
                setEditingId(row.id)
              }}
            >
              Edit
            </span>
            <div
              style={{
                width: 1,
                height: 20,
                background: "#f2f2f2",
                margin: "0px 6px",
              }}
            />
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                deleteAgent(row.id)
              }}
            >
              Delete
            </span>
          </div>
        )
      },
    },
  ]

  const [DataSource, loadingDataSource] = useAsyncLoad<DataSource[]>(
    getDataSource,
    [monthDateFilter, dateRangeFilter]
  )

  if (loadingDataSource || !DataSource) {
    return <Spin />
  }

  const sortedDataSource = sortBy(DataSource, (x) =>
    sum([x.approvedCertificates, x.approvedCourses, x.numberOfStudents])
  ).reverse()

  const dateFormat = "YYYY/MM/DD"

  return (
    <div>
      <section style={{ marginTop: 200 }}>
        <div className="container-fluid mt-100">
          <div className="row pl-50 pr-50">
            <Paper>
              <button
                className="btn btn-primary"
                id="add_course"
                onClick={() => {
                  setCreateAgentDialogVisible(true)
                }}
              >
                Add new agent
              </button>

              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  marginTop: 16,
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: 780,
                  zIndex: 999,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
                className="filter-buttons-container"
              >
                <MonthPicker
                  onChange={(date) => {
                    if (!date) {
                      setDateRangeFilter(null)
                      setMonthDateFilter(null)
                      return
                    }
                    setMonthDateFilter(date)
                    setDateRangeFilter(null)
                    return
                  }}
                  value={monthDateFilter}
                />

                <b style={{ margin: "0px 6px", color: "#999" }}>OR</b>

                <DateRange
                  onValueSelect={(e) => {
                    setDateRangeFilter(e)
                    setMonthDateFilter(null)
                  }}
                  value={dateRangeFilter}
                />
              </div>

              <Grid
                rows={sortedDataSource}
                columns={columns}
                getRowId={(r) => r.id}
              >
                <DragDropProvider />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <GroupingState defaultGrouping={[]} />
                <IntegratedGrouping />
                <PagingState defaultCurrentPage={0} pageSize={25} />
                <IntegratedPaging />

                <DataTypeProvider
                  for={["name", "id", "email"]}
                  availableFilterOperations={[
                    "endsWith",
                    "startsWith",
                    "contains",
                  ]}
                />

                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />

                <Table />

                <TableFilterRow showFilterSelector />

                <PagingPanel />
                <TableHeaderRow showSortingControls />
                <TableGroupRow />
                <Toolbar />

                <GroupingPanel />
              </Grid>
            </Paper>
          </div>
        </div>
      </section>

      {createAgentDialogVisible && (
        <CreateAgentDialog
          visible={createAgentDialogVisible}
          setVisible={setCreateAgentDialogVisible}
        />
      )}

      {editAgentDialog && editingId && (
        <EditAgentDialog
          visible={editAgentDialog}
          setVisible={setEditAgentDialog}
          agentId={editingId}
        />
      )}
      <Logout />
    </div>
  )
}

export default UsersTable
