import { Checkbox, Input, message, Modal, Select, Upload } from "antd"
import "antd/dist/antd.css"
import firebase from "firebase/app"
import { useFormik } from "formik"
import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument } from "react-firebase-hooks/firestore"
import { usersCollectionRef } from "../../config/collectionsRef"
import { getFileDownloadUrl } from "../certificates/getWordDownloadUrl"
import { imageUploadAndGetUrl } from "../utils/imageUploadAndGetUrl"
const { Option } = Select
const { Dragger } = Upload

interface Props {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateAgentDialog = (props: Props) => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())

  const userId = user?.uid ?? "NotLoad"
  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(userId)
  )

  const permission = userSnapshot?.data()?.permission
  const isFullAdmin = permission === "fullAdmin"

  const { setVisible, visible } = props
  const [uploading, setUploading] = useState(false)
  const [towSignature, setTowSignature] = useState(false)
  const [signatureImg, setSignatureImg] = useState()

  const certificateTypes = [
    { name: "Attendance", value: "attendance" },
    { name: "Achievement", value: "achievement" },
  ]

  const formik = useFormik({
    initialValues: {
      name: "",
      directorName: "",
      email: "",
      note: "",
      signatureImg: undefined,
      towSignature: false,
      PartnerOf: "",
      JobTitle: "",
      certificateTypes: ["attendance"],
      permission: "agent",
    },

    onSubmit: async (values) => {
      let signatureImgUrl = ""
      let imageUrl: string | undefined = undefined

      if (towSignature) {
        signatureImgUrl = await imageUploadAndGetUrl(
          signatureImg!,
          "signatures"
        )

        imageUrl = await getFileDownloadUrl(signatureImgUrl)
      }

      await usersCollectionRef.add({
        name: values.name,
        email: values.email,
        directorName: values.directorName,
        note: values.note,
        ...(imageUrl ? { signatureImg: imageUrl } : {}),
        towSignature: values.towSignature,
        PartnerOf: values.PartnerOf,
        JobTitle: values.JobTitle,
        certificateTypes: values.certificateTypes,
        permission: values.permission,
      })

      formik.resetForm()
      setVisible(false)
      await message.success(`Created Agent  ${formik.values.name}`)
    },
  })

  const isDisabled =
    formik.values.name.trim() == "" ||
    formik.values.certificateTypes.length === 0 ||
    (formik.values.towSignature &&
      (!signatureImg ||
        formik.values.JobTitle.trim() === "" ||
        formik.values.PartnerOf.trim() === ""))

  return (
    <Modal
      title="Create Agent"
      visible={visible}
      onOk={() => formik.submitForm()}
      onCancel={() => setVisible(false)}
      okButtonProps={{ disabled: isDisabled }}
      style={{ marginTop: 100 }}
    >
      <label style={{ marginTop: 25 }}> Agent Name * </label>
      <Input
        placeholder="Enter Agent Name"
        onChange={(e) => formik.setFieldValue("name", e.target.value)}
      />

      <label style={{ marginTop: 25 }}> Email * </label>
      <Input
        placeholder="email"
        onChange={(e) => formik.setFieldValue("email", e.target.value)}
      />

      <label style={{ marginTop: 25 }}> Agent director Name * </label>
      <Input
        placeholder="Agent director Name"
        onChange={(e) => formik.setFieldValue("directorName", e.target.value)}
      />

      <label style={{ marginTop: 25 }}> Certificate Types * </label>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Please select generate Certificate types "
        onChange={(e) => formik.setFieldValue("certificateTypes", e)}
        value={formik.values.certificateTypes}
      >
        {certificateTypes.map((type) => {
          return (
            <Option key={type.value} value={type.value}>
              {type.name}
            </Option>
          )
        })}
      </Select>

      <label style={{ marginTop: 25 }}> Note </label>
      <Input
        placeholder="Enter Note"
        onChange={(e) => formik.setFieldValue("note", e.target.value)}
      />

      {isFullAdmin && (
        <Checkbox
          style={{ marginTop: 25 }}
          checked={towSignature}
          onChange={() => {
            setTowSignature(!towSignature),
              formik.setFieldValue("towSignature", !towSignature)
          }}
        >
          Do You Want This Agent certificates With Two Signature
        </Checkbox>
      )}

      {towSignature ? (
        <>
          <label style={{ marginTop: 25 }}> Upload You Signature * </label>
          <div style={{ width: "100%", height: 100 }}>
            {signatureImg ? (
              <img
                src={window.URL.createObjectURL(signatureImg)}
                alt="avatar"
                style={{ width: "50%", height: "100%" }}
              />
            ) : (
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setSignatureImg(e.target.files[0] as any)
                  }

                  e.target.value = ""
                }}
              />
            )}
          </div>
          <p
            style={{
              background: " #f7f7ce",
              padding: 12,
              border: "1px solid #ccc",
              borderRadius: 4,
            }}
          >
            Signature image size must be: Width: 130 X height: 50{" "}
          </p>

          <label style={{ marginTop: 25 }}> Partner Of * </label>
          <Input
            placeholder="Enter Partner sOf"
            onChange={(e) => formik.setFieldValue("PartnerOf", e.target.value)}
          />
          <label style={{ marginTop: 25 }}> Job Title * </label>
          <Input
            placeholder="Enter  Job Title *"
            onChange={(e) => formik.setFieldValue("JobTitle", e.target.value)}
          />
        </>
      ) : null}
    </Modal>
  )
}

export default CreateAgentDialog
