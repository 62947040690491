import { Checkbox, Input, message, Modal, Select, Upload } from "antd"
import "antd/dist/antd.css"
import firebase from "firebase/app"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore"
import { usersCollectionRef } from "../../config/collectionsRef"
import { getFileDownloadUrl } from "../certificates/getWordDownloadUrl"
import { imageUploadAndGetUrl } from "../utils/imageUploadAndGetUrl"
const { Option } = Select
const { Dragger } = Upload

interface Props {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  agentId: string
}

const EditAgentDialog = (props: Props) => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())
  const { setVisible, visible, agentId } = props
  const [firstLoad, setFirstLoad] = useState(true)
  const userId = user?.uid ?? "NotLoad"
  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(userId)
  )

  const [agentSnaphot] = useDocumentData<any>(usersCollectionRef.doc(agentId))

  const permission = userSnapshot?.data()?.permission
  const isFullAdmin = permission === "fullAdmin"

  const [uploading, setUploading] = useState(false)
  const [signatureImg, setSignatureImg] = useState()

  const certificateTypes = [
    { name: "Attendance", value: "attendance" },
    { name: "Achievement", value: "achievement" },
  ]

  const formik = useFormik({
    initialValues: {
      name: "",
      directorName: "",
      email: "",
      note: "",
      signatureImg: undefined,
      towSignature: false,
      PartnerOf: "",
      JobTitle: "",
      certificateTypes: ["attendance"],
      permission: "agent",
    },

    onSubmit: async (values) => {
      let signatureImgUrl
      let imageUrl: string | undefined = undefined

      if (signatureImg) {
        signatureImgUrl = await imageUploadAndGetUrl(
          signatureImg!,
          "signatures"
        )


        imageUrl = await getFileDownloadUrl(signatureImgUrl)

      }

      await usersCollectionRef.doc(agentId).set(
        {
          name: values.name,
          email: values.email,
          directorName: values.directorName,
          note: values.note,
          ...(imageUrl ? { signatureImg: imageUrl } : {}),
          towSignature: values.towSignature,
          PartnerOf: values.PartnerOf,
          JobTitle: values.JobTitle,
          certificateTypes: values.certificateTypes,
          permission: values.permission,
        },
        { merge: true }
      )

      formik.resetForm()
      setVisible(false)
      await message.success(`Updated Agent  ${formik.values.name}`)
    },
  })

  useEffect(() => {
    if (!agentSnaphot || !firstLoad) {
      return
    }
    setFirstLoad(false)
    formik.setValues(agentSnaphot)
  }, [agentSnaphot, firstLoad])

  const isDisabled =
    formik.values.name.trim() == "" ||
    formik.values.certificateTypes.length === 0 ||
    (formik.values.towSignature &&
      ((!signatureImg && !formik.values.signatureImg) ||
        formik.values.JobTitle.trim() === "" ||
        formik.values.PartnerOf.trim() === ""))

  return (
    <Modal
      title="Edit Agent"
      visible={visible}
      onOk={() => formik.submitForm()}
      onCancel={() => setVisible(false)}
      okButtonProps={{ disabled: isDisabled }}
      style={{ marginTop: 100 }}
    >
      <label style={{ marginTop: 25 }}> Agent Name * </label>
      <Input
        placeholder="Enter Agent Name"
        onChange={(e) => formik.setFieldValue("name", e.target.value)}
        value={formik.values.name}
      />

      <label style={{ marginTop: 25 }}> Agent director Name * </label>
      <Input
        placeholder="Agent director Name"
        onChange={(e) => formik.setFieldValue("directorName", e.target.value)}
        value={formik.values.directorName}
      />

      <label style={{ marginTop: 25 }}> Certificate Types * </label>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Please select generate Certificate types "
        onChange={(e) => formik.setFieldValue("certificateTypes", e)}
        value={formik.values.certificateTypes}
      >
        {certificateTypes.map((type) => {
          return (
            <Option key={type.value} value={type.value}>
              {type.name}
            </Option>
          )
        })}
      </Select>

      <label style={{ marginTop: 25 }}> Note </label>
      <Input
        placeholder="Enter Note"
        onChange={(e) => formik.setFieldValue("note", e.target.value)}
        value={formik.values.note}
      />

      {isFullAdmin && (
        <Checkbox
          style={{ marginTop: 25 }}
          checked={formik.values.towSignature}
          onChange={() => {
            formik.setFieldValue("towSignature", !formik.values.towSignature)
          }}
        >
          Do You Want This Agent certificates With Two Signature
        </Checkbox>
      )}

      {formik.values.towSignature ? (
        <>
          <label style={{ marginTop: 25 }}> Upload You Signature * </label>
          <div style={{ width: "100%", height: 100 }}>
            {signatureImg ? (
              <img
                src={window.URL.createObjectURL(signatureImg)}
                alt="avatar"
                style={{ width: "50%", height: "100%" }}
              />
            ) : (
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setSignatureImg(e.target.files[0] as any)
                  }

                  e.target.value = ""
                }}
              />
            )}
          </div>

          <label style={{ marginTop: 25 }}> Partner Of * </label>
          <Input
            placeholder="Enter Partner sOf"
            onChange={(e) => formik.setFieldValue("PartnerOf", e.target.value)}
            value={formik.values.PartnerOf}
          />
          <label style={{ marginTop: 25 }}> Job Title * </label>
          <Input
            placeholder="Enter  Job Title *"
            onChange={(e) => formik.setFieldValue("JobTitle", e.target.value)}
            value={formik.values.JobTitle}
          />
        </>
      ) : null}
    </Modal>
  )
}

export default EditAgentDialog
