import firebase from "firebase/app"

export async function getUserPermission(userId: string) {
  const user = await firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .get()
    .then((e) => {
      return e.data()
    })

  return await user?.permission
}
