import {
  Checkbox,
  DatePicker,
  Input,
  message,
  Modal,
  Select,
  Upload,
} from "antd"
import "antd/dist/antd.css"
import firebase from "firebase/app"
import { useFormik } from "formik"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument } from "react-firebase-hooks/firestore"
import { useCollection } from "react-firebase-hooks/firestore/"
import { coursesRef, usersCollectionRef } from "../../config/collectionsRef"
const { Option } = Select
const { Dragger } = Upload

interface Props {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

interface Course {
  name: string
  towSignature: boolean
  agent: string
  status: "approved" | "pendingApproval"
  endDate: Date
  createdAt: Date
  signatureImg?: string
  directorName?: string
  PartnerOf?: string
  JobTitle?: string
}

const AddCourseDialog = (props: Props) => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())

  const userId = user?.uid ?? "NotLoad"

  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(userId)
  )

  const permission = userSnapshot?.data()?.permission

  const isFullAdmin = permission === "fullAdmin"

  const { setVisible, visible } = props
  const [usersSnapshot, loading] = useCollection(usersCollectionRef)
  const [towSignature, setTowSignature] = useState(false)

  const formik = useFormik({
    initialValues: {
      courseName: "",
      users: [userId],
      towSignature: false,
      endDate: undefined as any,
      agent: userId,
      status: isFullAdmin ? "approved" : "pendingApproval",
    },

    onSubmit: async (values) => {
      const selectedAgent = usersSnapshot?.docs
        .find((x) => x.id === formik.values.agent)
        ?.data()

      if (!selectedAgent) {
        return
      }

      await coursesRef.add({
        name: values.courseName.trim(),
        towSignature: values.towSignature,
        agent: values.agent,
        status: isFullAdmin ? "approved" : "pendingApproval",
        endDate: values.endDate as any,
        createdAt: new Date(),
        ...(values.towSignature
          ? {
              signatureImg: selectedAgent.signatureImg,
              directorName: selectedAgent.directorName,
              PartnerOf: selectedAgent.PartnerOf,
              JobTitle: selectedAgent.JobTitle,
            }
          : {}),
      } as Course)

      formik.resetForm()
      setVisible(false)
      await message.success(`Created Course  ${formik.values.courseName}`)
    },
  })

  const slectedAgent = useMemo(() => {
    return usersSnapshot?.docs.find((x) => x.id === formik.values.agent)
  }, [formik])

  if (loading) {
    return null
  }

  const isDisabled =
    formik.values.courseName.trim() == "" || formik.values.users.length == 0

  return (
    <Modal
      title="Create Course"
      visible={visible}
      onOk={() => formik.submitForm()}
      onCancel={() => setVisible(false)}
      okButtonProps={{ disabled: isDisabled }}
      style={{ marginTop: 100 }}
    >
      <label style={{ marginTop: 25 }}> Course Name * </label>
      <Input
        placeholder="Enter Course Name"
        onChange={(e) => formik.setFieldValue("courseName", e.target.value)}
      />

      {isFullAdmin && (
        <>
          <label style={{ marginTop: 25 }}>
            Course Agents <b style={{ color: "red" }}>*</b>{" "}
          </label>
          <Select
            style={{ width: "100%" }}
            placeholder="Please select Agent"
            onChange={(e) => {
              formik.setFieldValue("agent", e)
            }}
          >
            {usersSnapshot?.docs.map((user) => {
              return (
                <Option key={user.id} value={user.id}>
                  {user?.data().name}
                </Option>
              )
            })}
          </Select>
        </>
      )}

      <label style={{ marginTop: 25 }}>
        End Date <b style={{ color: "red" }}>*</b>{" "}
      </label>

      <DatePicker
        // value={moment(formik.values.endDate)}

        value={
          formik.values.endDate instanceof firebase.firestore.Timestamp
            ? moment(
                (formik.values.endDate as firebase.firestore.Timestamp).toDate()
              )
            : moment(formik.values.endDate)
        }
        style={{ width: "100%" }}
        placeholder="Select End Date"
        onChange={(x) => {
          if (!x) {
            return
          }
          formik.setFieldValue("endDate", new Date(x.toDate()))
        }}
      />

      {isFullAdmin && slectedAgent?.data().towSignature && (
        <Checkbox
          style={{ marginTop: 25 }}
          checked={towSignature}
          onChange={() => {
            setTowSignature(!towSignature),
              formik.setFieldValue("towSignature", !towSignature)
          }}
        >
          Do You Wont This Certificate With two Signature
        </Checkbox>
      )}
    </Modal>
  )
}

export default AddCourseDialog
