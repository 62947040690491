import { DatePicker } from "antd"
import React, { useEffect, useState } from "react"

interface Props {
  onValueSelect: (date: [moment.Moment, moment.Moment] | null) => void
  value: [moment.Moment, moment.Moment] | null
}

export function DateRange(props: Props) {
  const [fromDate, setFromDate] = useState<moment.Moment | null>()
  const [toDate, setToDate] = useState<moment.Moment | null>()
  const { onValueSelect, value } = props

  useEffect(() => {
    if (!fromDate || !toDate) {
      return
    }

    onValueSelect([fromDate, toDate])
  }, [fromDate, toDate])

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <DatePicker
        placeholder={fromDate ? fromDate.format("YYYY-MM-DD") : "From"}
        onChange={(e) => {
          setFromDate(e)
          if (e === null) {
            onValueSelect(null)
          }
        }}
        value={value ? value[0] : null}
      />

      <DatePicker
        placeholder={toDate ? toDate.format("YYYY-MM-DD") : "To"}
        onChange={(e) => {
          setToDate(e)
          onValueSelect(null)
        }}
        value={value ? value[1] : null}
      />
    </div>
  )
}
