/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"

export type AsyncLoadResult = [any | undefined, boolean]

export function useAsyncLoad<T>(
  func: (() => Promise<T>) | any,
  watchChanges: Array<any>,
  onError?: (error: string) => void
): [T, boolean] {
  const [result, setResult] = useState<AsyncLoadResult>([undefined, true])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await func()
        setResult([res, false])
        setLoading(false)
      } catch (e) {
        if (onError) {
          onError(e)
        }
        setResult([undefined, false])
        setLoading(false)
      }
    })()
  }, watchChanges)

  return [result[0] as T, result[1] || loading]
}
