import {
  ChangeSet,
  DataTypeProvider,
  EditingState,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid"
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui"
import Paper from "@material-ui/core/Paper"
import { Divider, Spin } from "antd"
import firebase from "firebase/app"
import { map, sortBy } from "lodash"
import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { Logout } from "../../components/logout"
import { coursesRef, usersCollectionRef } from "../../config/collectionsRef"
import { Course } from "../../database/interfaces"
import AddCourseDialog from "../components/addCourseDialog"
import EditCourseDialog from "../components/editCourseDialog"

export interface CourseWithId {
  snapshot: Course
  id: string
}

const CoursesTable = () => {
  const [user, isLoadingUser] = useAuthState(firebase.auth())

  const userId = user?.uid ?? "NotLoad"
  const [userSnapshot, loadingUserData] = useDocument(
    usersCollectionRef.doc(userId)
  )

  const permission = userSnapshot?.data()?.permission

  const isFullAdmin = permission === "fullAdmin"

  const query = isFullAdmin
    ? coursesRef
    : coursesRef.where("agent", "==", userId)

  const [courseSnapshot, loading] = useCollection(query)
  const [usersSnapshot, loadingUsers] = useCollection(usersCollectionRef)
  const [addCourseDialog, setAddCourseDialog] = useState(false)
  const [editCourseDialog, setEitCourseDialog] = useState(false)
  const [editCourseId, setEitCourseId] = useState("")

  const deleteCourse = (courseId: string) => {
    const r = window.confirm("Are you sure you wont to Delete This Course?")
    if (r == true) {
      coursesRef.doc(courseId).delete()
    }
  }

  const commitChanges = ({ added, changed }: ChangeSet) => {
    if (added) {
      map(added, (row) => {
        coursesRef.add(row).then(() => {
          alert("Successfully Added")
        })
      })
    }

    if (changed) {
      map(changed, (changedFields, docId) => {
        coursesRef
          .doc(docId)
          .update(changedFields)
          .then(() => {
            alert("Successfully Updated")
          })
      })
    }
  }

  const status = {
    approved: { text: "Approved", color: "#006317" },
    pendingApproval: { text: "Pending Approval", color: "#d49950" },
    rejected: { text: "Rejected", color: "#9e000f" },
  }

  const dataSource = courseSnapshot === undefined ? [] : courseSnapshot.docs

  if (isLoadingUser || loadingUserData) {
    return <Spin />
  }

  const sortedCourses = sortBy(dataSource, (x) => x.data().createdAt)
    .map((s) => {
      return {
        ...s.data(),
        id: s.id,
        createdAt: s.data()?.createdAt?.toDate().toDateString(),
        endDate: s.data()?.endDate?.toDate().toDateString(),
      }
    })
    .reverse()

  const columns = [
    {
      name: "name",
      title: "Course Name",
      key: "name",
    },

    {
      ...(isFullAdmin && {
        title: "Agent",
        name: "agent",
        key: "agent",
        getCellValue: (row: any) => {
          const userName =
            usersSnapshot?.docs.find((e) => e.id == row.agent)?.data().name ??
            ""

          return <span>{userName}</span>
        },
      }),
    },

    { name: "createdAt", key: "createdAt", title: "Created Date" },

    { name: "endDate", key: "endDate", title: "End Date" },

    {
      name: "status",
      key: "status",
      title: "Status",
      getCellValue: (row: any) => {
        return (
          <span style={{ color: status[row.status]?.color }}>
            {" "}
            {status[row.status]?.text}{" "}
          </span>
        )
      },
    },

    {
      title: "Actions",
      key: "Actions",
      getCellValue: (row: any) => {
        return (
          <div style={{ display: "flex" }}>
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                setEitCourseDialog(true)
                setEitCourseId(row.id)
              }}
            >
              Edit
            </span>

            <div
              style={{
                width: 1,
                height: 20,
                background: "#f2f2f2",
                margin: "0px 6px",
              }}
            />
            {isFullAdmin ? (
              <span
                style={{ color: "#007bff", cursor: "pointer" }}
                onClick={() => {
                  deleteCourse(row.id)
                }}
              >
                Delete
              </span>
            ) : null}

            {isFullAdmin && row.status === "pendingApproval" && (
              <>
                <Divider type="vertical" />

                <span
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => {
                    coursesRef
                      .doc(row.id)
                      .update({ status: "approved" } as Course)
                  }}
                >
                  Approve
                </span>

                <Divider type="vertical" />

                <span
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => {
                    coursesRef
                      .doc(row.id)
                      .update({ status: "rejected" } as Course)
                  }}
                >
                  Redject
                </span>
              </>
            )}
          </div>
        )
      },
    },
  ].filter((e) => e.key !== undefined)

  return (
    <div>
      <section style={{ marginTop: 200 }}>
        <div className="container-fluid">
          <div className="row pl-50 pr-50">
            <Paper>
              <Grid
                rows={sortedCourses}
                columns={columns}
                getRowId={(r) => r.id}
              >
                <DragDropProvider />

                <EditingState onCommitChanges={commitChanges} />

                <SortingState defaultSorting={[]} />
                <IntegratedSorting />
                <GroupingState defaultGrouping={[]} />
                <IntegratedGrouping />
                <PagingState defaultCurrentPage={0} pageSize={15} />
                <IntegratedPaging />

                <DataTypeProvider
                  for={["courseName", "id"]}
                  availableFilterOperations={[
                    "endsWith",
                    "startsWith",
                    "contains",
                  ]}
                />

                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />

                <Table />

                <TableFilterRow showFilterSelector />

                <PagingPanel />
                <TableHeaderRow showSortingControls />
                <TableGroupRow />
                <Toolbar />

                <GroupingPanel />
                <TableEditRow />

                <TableEditColumn />

                <button
                  className="btn btn-primary"
                  id="add_course"
                  onClick={() => {
                    setAddCourseDialog(true)
                  }}
                >
                  Add New Course
                </button>
              </Grid>
            </Paper>
          </div>
        </div>
      </section>
      <AddCourseDialog
        setVisible={setAddCourseDialog}
        visible={addCourseDialog}
      />

      {editCourseDialog ? (
        <EditCourseDialog
          setVisible={setEitCourseDialog}
          visible={editCourseDialog}
          courseId={editCourseId}
        />
      ) : null}

      <Logout />
    </div>
  )
}

export default CoursesTable
