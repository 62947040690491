import firebase from "firebase/app"
import { useFormik } from "formik"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router"
import "../../css"
import "../../scss/index.scss"
import locales from "../../translate/locales"
import { getUserPermission } from "../utils/getUserPermission"


const Login = () => {
  const locale = "en"
  const history = useHistory()

  const texts = locales[locale as "en"]
  const translate = texts.loginPage

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: async (values) => {
      await firebase
        .auth()
        .signInWithEmailAndPassword(values.email!, values.password!)
        .then(async (e) => {
          const par = await getUserPermission(e.user?.uid ?? "")

          if (par == "fullAdmin") {
            history.push(`/agents-Table`)
          }
          if (par == "agent") {
            history.push(`/certificates-create`)
          }
        })
        .catch((error: any) => {
          console.log(error)
        })
    },
  })

  return (
    <div className="container">
      <Helmet bodyAttributes={{ class: `locale-${locale}` }} />
      <div className="row">
        <div className="col-12">
          <div className="logInBox">
            <div className='logInBoxLogo'>
              <img src='https://mastery-academy.netlify.app/images/logo-wide-04-02.png' style={{ margin: "0px 6px" }} />
            </div>

            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{translate.email}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e: any) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>{translate.password}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e: any) =>
                    formik.setFieldValue("password", e.target.value)
                  }
                />
              </Form.Group>

              <Button
                variant="primary"
                // disabled={!formik.isValid}
                onClick={() => formik.submitForm()}
                style={{ background: "#002d62", width: '100%' }}
              >
                {translate.login}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
