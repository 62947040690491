import firebase from "firebase/app"
import { max, sortBy } from "lodash"
import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDocument } from "react-firebase-hooks/firestore/"
import { Logout } from "../../components/logout"
import {
  certificatesRef,
  coursesRef,
  usersCollectionRef,
} from "../../config/collectionsRef"
import "../../config/firebase"
import "../../css/index"
import { Course } from "../../database/interfaces"
import { useAsyncLoad } from "../../hooks/useAsyncLoad"
import CourseCertificatesList from "../components/CourseCertificatesList"

interface CourseRecord extends Course {
  id: string
  lastCertificateDate: Date
}

const CertificatesSearch = () => {
  const [agentUser] = useAuthState(firebase.auth())

  const [searchNumber, setSearchNumber] = useState<string | undefined>()
  const [userSnapshot] = useDocument(
    usersCollectionRef.doc(agentUser?.uid ?? "Loading")
  )

  const permission = userSnapshot?.data()?.permission

  const isFullAdmin = permission === "fullAdmin"
  const agentId = agentUser?.uid ?? "NotLoad"

  const query = isFullAdmin
    ? coursesRef.where("status", "==", "approved")
    : coursesRef.where("agent", "==", agentId).where("status", "==", "approved")


  const getCoursesData = async () => {
    const courseSnapshot = await query.get()

    if (!courseSnapshot) {
      return
    }

    const result = await Promise.all(
      (courseSnapshot || []).docs.map(async (courseDoc) => {
        const course = courseDoc.data() as Course

        const courseId = courseDoc.id
        const certificates = await certificatesRef
          .where("courseId", "==", courseId)
          .where("status", "==", "approved")
          .get()
        const datesList = certificates.docs.map((x) =>
          (x.data().generatedAt.toDate() as Date).getTime()
        )


        return {
          ...course,
          lastCertificateDate: max(datesList),
          id: courseDoc.id,
        }
      })
    )

    return result
  }

  const [courses, loading] = useAsyncLoad<CourseRecord[]>(getCoursesData, [
    isFullAdmin,
  ])

  if (!courses || loading) {
    return null
  }

  const sortedDataSource = sortBy(
    courses,
    (x) => x.lastCertificateDate
  ).reverse()

  return (
    <>
      <section style={{ minHeight: 600, padding: "200px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="input-group md-form form-sm form-1 pl-0">
              <div className="input-group-prepend">
                <span
                  style={{ cursor: "pointer" }}
                  className="input-group-text purple lighten-3"
                  id="basic-text1"
                  onClick={() => {}}
                >
                  <i
                    className="fas fa-search text-white"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
              <input
                className="form-control my-0 py-1"
                type="text"
                placeholder="Pleas Enter Your Certificate Number"
                aria-label="Search"
                onChange={(e) => setSearchNumber(e.target.value)}
                minLength={8}
              />
            </div>

            <div className="col-12" style={{ padding: 0 }}>
              {sortedDataSource.map((course) => {
                return (
                  <CourseCertificatesList
                    key={course.id}
                    courseId={course.id}
                    isFullAdmin={isFullAdmin}
                    search={searchNumber}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <Logout />
    </>
  )
}

export default CertificatesSearch
